import { styled } from "@mui/system"
import { NavLink, NavLinkProps } from "components/navigation/NavLink"
import React from "react"

interface TopNavigationVerticalMenuProps {
  menuItems: NavLinkProps[]
}

const TopNavigationVerticalMenu = (props: TopNavigationVerticalMenuProps) => {
  const { menuItems } = props

  return (
    <List>
      {menuItems.map((menuItem) => {
        return (
          <ListItem key={menuItem.href}>
            <_NavLink
              onClick={menuItem.onClick}
              href={menuItem.href}
              variant={menuItem.variant}
              text={menuItem.text}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default TopNavigationVerticalMenu

const List = styled("ul")(({ theme }) => ({
  width: "100%",
  listStyle: "none",
  padding: "0",
  margin: 0,
  backgroundColor: theme.palette.primary.lightest,
}))

const ListItem = styled("li")({
  width: "100%",
  height: "55px",
})

const _NavLink = styled(NavLink)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "0",
  margin: 0,
  ...theme.fonts.lato700,
  "&.MuiButton-outlinedPrimary.MuiButton-outlinedPrimary": {
    border: "none",
  },
  "&.MuiButton-outlined.MuiButton-outlined": {
    border: "none",
  },
})) as typeof NavLink
