import { useState, useCallback, useMemo } from "react"

export const useBoolean = (defaultValue: boolean | (() => boolean)) => {
  const [value, setValue] = useState(defaultValue)

  const on = useCallback(() => setValue(true), [])
  const off = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue((previousValue) => !previousValue), [])

  return useMemo(
    () => ({
      value,
      on,
      off,
      toggle,
      set: setValue,
    }),
    [value, on, off, toggle],
  )
}
