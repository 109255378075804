import { Box } from '@mui/material';
import { makeStyles } from "tss-react/mui-compat"
import useCurrent from "lib/use-current"

const useStyles = makeStyles<{
  size: string
}>()(
  (theme, props) => ({
    avatarContainer: {
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      width: props.size,
      height: props.size,
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
  }),
)

interface IUserInitials {
  size?: string
}

export default function UserInitials(props: IUserInitials) {
  const { size = "50px" } = props
  const current = useCurrent()
  const { classes } = useStyles({ size })

  const userInitials = () => {
    let initials = ""
    // @ts-ignore
    if (current.user) {
      // @ts-ignore
      initials = current.user.first_name[0].toUpperCase() + current.user.last_name[0].toUpperCase()
    }
    return initials
  }

  return <Box className={classes.avatarContainer}>{userInitials()}</Box>
}
