import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui-compat"
import { ReactNode } from "react"

const useStyles = makeStyles<{
  alignItems: "center" | "left" | "start"
  flexDirection: "column" | "row"
}>()(
  (theme, props) => ({
    root: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      maxWidth: "1440px",
      width: "100%",
      flexDirection: props.flexDirection,
      alignItems: props.alignItems,
      [theme.breakpoints.up("md")]: {
        paddingRight: "40px",
        paddingLeft: "40px",
      },
      [theme.breakpoints.up("xs")]: {
        paddingRight: "15px",
        paddingLeft: "15px",
      },
    },
  })
)

interface IContentContainer {
  alignItems?: "center" | "left" | "start"
  flexDirection?: "column" | "row"
  children?: ReactNode
  className?: string
}

export const ContentContainer = (props: IContentContainer) => {
  const { alignItems = "center", flexDirection = "column", children, className } = props

  const { classes, cx } = useStyles({ alignItems, flexDirection })

  return (
    <Box className={cx(classes.root, className)} role="region" aria-label="content">
      <Box className={classes.content}>{children}</Box>
    </Box>
  )
}
