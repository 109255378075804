import { AuthDialog } from "app-constants/constants"
import useAuthDialog from "hooks/useAuthDialog"
import useCurrent from "lib/use-current"
import { useRouter } from "next/router"
import { OpenedAuthDialog } from "recoil-state/atoms"

interface UseAuthRedirectProps {
  defaultRoute?: string
  defaultDialog?: OpenedAuthDialog
}

interface UseAuthRedirectCallbackOptions {
  routeToRedirect?: string | null
  dialogToOpen?: OpenedAuthDialog | null
}

export default function useAuthRedirect<T>(props: UseAuthRedirectProps = {}) {
  const { defaultRoute, defaultDialog = { dialog: AuthDialog.SIGN_IN } } = props
  const current = useCurrent()
  const router = useRouter()

  const authDialogs = useAuthDialog()

  return async (
    callback?: () => void,
    callbackOptions: UseAuthRedirectCallbackOptions = {},
  ): Promise<T | void> => {
    const { routeToRedirect, dialogToOpen } = callbackOptions
    if (!current) {
      console.warn("useAuthRedirect callback called without having loaded current yet")
      return
    }

    if (current.user) return callback?.() as T

    const selectedRouteToRedirect =
      routeToRedirect ?? routeToRedirect === null ? routeToRedirect : defaultRoute
    if (selectedRouteToRedirect) await router.push(selectedRouteToRedirect)

    const selectedDialogToOpen = dialogToOpen !== undefined ? dialogToOpen : defaultDialog
    authDialogs._set(selectedDialogToOpen ?? { dialog: null })
  }
}
