import { Grow, Box } from "@mui/material"
import { styled } from "@mui/system"
import { NAVIGATION_HEIGHT } from "app-constants/constants"
import React from "react"
import { pxToRem } from "utils/helpers"

interface TopNavigationDropdownProps {
  children: React.ReactNode
  isOpen: boolean
}

const TopNavigationDropdown = (props: TopNavigationDropdownProps) => {
  const { children, isOpen } = props

  return (
    <Grow in={isOpen}>
      <_Container>{children}</_Container>
    </Grow>
  )
}

export default TopNavigationDropdown

const _Container = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: NAVIGATION_HEIGHT - 20,
  left: "0",
  width: "100%",
  maxHeight: `calc(100vh - ${NAVIGATION_HEIGHT}px)`,
  zIndex: 100,
  paddingBottom: pxToRem(100),

  backgroundColor: theme.palette.gray.lightest,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.25)",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },

  [theme.breakpoints.up("sm")]: {
    maxWidth: "400px",
    left: "9%",
    paddingBottom: 0,
  },
}))
