import { IconButton } from "@mui/material"
import { createPath, ROUTE } from "app-constants/routing"
import UserInitials from "components/UserInitials"
import Link from "next/link"
import React from "react"

interface IAccountLink {
  path?: ROUTE.ACCOUNT_MAIN | ROUTE.EMBED_ACCOUNT_MAIN
}

const AccountLink = ({ path = ROUTE.ACCOUNT_MAIN }: IAccountLink) => {
  return (
    <Link href={createPath({ path })} passHref legacyBehavior>
      <IconButton aria-label="account details" size="small">
        <UserInitials />
      </IconButton>
    </Link>
  )
}

export default AccountLink
