import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { NavLink, NavLinkProps } from "components/navigation/NavLink"
import React from "react"

interface TopNavigationHorizontalMenuProps {
  className?: string
  menuItems: NavLinkProps[]
}

export const TopNavigationHorizontalMenu = (props: TopNavigationHorizontalMenuProps) => {
  const { className, menuItems } = props

  return (
    <_Container className={className}>
      <Box display="flex" flexDirection="row">
        {menuItems.map((menuItem) => (
          <_NavLinkContainer key={menuItem.href}>
            <NavLink {...menuItem} />
          </_NavLinkContainer>
        ))}
      </Box>
    </_Container>
  )
}

const _Container = styled(Box)({
  display: "flex",
  flexFlow: "row nowrap",
})

const _NavLinkContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "24px",
})
