import Typography from "@mui/material/Typography"
import { styled } from "@mui/system"
import { createPath, ROUTE } from "app-constants/routing"
import useCurrent, { Current } from "lib/use-current"
import Link from "next/link"

interface NavigationLogoProps {
  className?: string
}

const NavigationLogo = (props: NavigationLogoProps) => {
  const { className } = props
  const current = useCurrent()

  if (!current?.config) return null

  const logoAltText = current?.config?.logo_url
    ? current?.config?.provider_name
    : current?.config?.app_name

  return (
    <Link href={createPath({ path: ROUTE.CONVENTIONS })} passHref legacyBehavior>
      <CustomLink className={className} current={current}>
        <Typography className="sr-only">{logoAltText}</Typography>
      </CustomLink>
    </Link>
  )
}

export default NavigationLogo

const CustomLink = styled("a", {
  shouldForwardProp: (prop) => prop !== "current",
})<{ current: Current }>(({ current }) => ({
  cursor: "pointer",
  width: "150px",
  height: "50px",
  maxWidth: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center left",
  backgroundImage: `url('${current?.config?.logo_url || current?.config?.app_logo_url}')`,
}))
