import { Box, useMediaQuery } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { styled } from "@mui/system"
import { AuthDialog } from "app-constants/constants"
import { createPath, ROUTE } from "app-constants/routing"
import NavigationLogo from "components/NavigationLogo"
import AccountLink from "components/account/AccountLink"
import { NavLinkProps } from "components/navigation/NavLink"
import TopNavigationContainer from "components/navigation/TopNavigationContainer"
import TopNavigationHamburgerMenu from "components/navigation/TopNavigationHamburgerMenu"
import { TopNavigationHorizontalMenu } from "components/navigation/TopNavigationHorizontalMenu"
import TopNavigationVerticalMenu from "components/navigation/TopNavigationVerticalMenu"
import { useExhibitorCopyText } from "hooks/exhibitors/useExhibitorsCopyText"
import useAuthDialog from "hooks/useAuthDialog"
import { useUser } from "hooks/useUser"
import useCurrent from "lib/use-current"
import React, { useMemo } from "react"

const TopMacroNavigation = () => {
  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
  const authDialog = useAuthDialog()

  const exhibitorsCopyText = useExhibitorCopyText()
  const current = useCurrent()
  const user = useUser()
  // @ts-ignore
  const currentConfig = current?.config

  const authNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: "/",
        text: "Sign In",
        variant: "outlined",
        onClick: (event) => {
          event.preventDefault()
          event.stopPropagation()
          authDialog.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})
        },
      },
      {
        href: "/",
        text: "Create an Account",
        variant: "contained",
        onClick: (event) => {
          event.preventDefault()
          event.stopPropagation()
          authDialog.set(AuthDialog.SIGN_UP)
        },
      },
    ],
    [],
  )

  const generalNavLinks: NavLinkProps[] = useMemo(
    () => [
      {
        href: createPath({ path: ROUTE.CONVENTIONS }),
        text: currentConfig?.expo_copy_text ?? "Conventions",
      },
      {
        href: createPath({ path: ROUTE.EXHIBITORS }),
        text: exhibitorsCopyText!.plural,
        isActive: (router) => router.route === createPath({ path: ROUTE.EXHIBITORS_BROWSE_ALL }),
      },
      ...(currentConfig?.people_index
        ? [
            {
              href: createPath({ path: ROUTE.PEOPLE }),
              text: "People",
            },
          ]
        : []),

      {
        href: createPath({ path: ROUTE.VIDEOS }),
        text: "Videos",
      },
      {
        href: createPath({ path: ROUTE.ITINERARIES }),
        text: "My Schedule",
        onClick: (event) => {
          if (!user) {
            event.preventDefault()
            event.stopPropagation()
            authDialog.set(AuthDialog.JOIN, { preamble: '', dialogHeader: 'Sign In'})
          }
        },
      },
    ],
    [currentConfig?.expo_copy_text, currentConfig?.people_index, exhibitorsCopyText, user],
  )

  if (!currentConfig?.expo_copy_text) return null

  return (
    <TopNavigationContainer>
      <_Container>
        {!isWideScreen && (
          <TopNavigationHamburgerMenu>
            <TopNavigationVerticalMenu menuItems={generalNavLinks} />
            {!user && <TopNavigationVerticalMenu menuItems={authNavLinks} />}
          </TopNavigationHamburgerMenu>
        )}

        <_NavigationLogo />

        {isWideScreen && (
          <_MainNavigationContainer>
            <TopNavigationHorizontalMenu menuItems={generalNavLinks} />
            {!user && <_TopNavigationHorizontalAuthMenu menuItems={authNavLinks} />}
          </_MainNavigationContainer>
        )}

        {user && <AccountLink />}
      </_Container>
    </TopNavigationContainer>
  )
}

export default TopMacroNavigation

const _TopNavigationHorizontalAuthMenu = styled(TopNavigationHorizontalMenu)({
  marginLeft: "32px",
  ".MuiButton-root.MuiButton-root": {
    borderRadius: "10px",
  },
})

const _Container = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  flexFlow: "nowrap row",
  justifyContent: "space-between",
  alignItems: "center",
})

const _MainNavigationContainer = styled(_Container)({
  marginLeft: "auto",
  width: "auto",
})

const _NavigationLogo = styled(NavigationLogo)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.up("lg")]: {
    margin: 0,
  },
}))
