import { Box, Button, ButtonProps } from "@mui/material"
import { makeStyles } from "tss-react/mui-compat"
import clsx from "clsx"
import { NextRouter } from "next/dist/shared/lib/router/router"
import Link from "next/link"
import { useRouter } from "next/router"

export interface NavLinkProps {
  href: string
  variant?: ButtonProps["variant"]
  text: string
  className?: ButtonProps["className"]
  isActive?: (router: NextRouter) => boolean
  onClick?: ButtonProps["onClick"]
}

const useStyles = makeStyles()(
  (theme) => ({
    button: {
      "&.MuiButton-root": {
        textTransform: "uppercase",
      },
      "&.MuiButton-text": {
        color: theme.palette.gray.darker,
      },
      "&.MuiButton-outlinedPrimary": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    buttonTextUnderline: {
      borderBottom: `1.5px solid ${theme.palette.gray.darker}`,
    },
  }),
)

export const NavLink = (props: NavLinkProps) => {
  const { href, variant = "text", text, className, isActive: isActiveCallback, onClick } = props
  const { classes } = useStyles()

  const router = useRouter()

  const pathWithoutQuery = router.asPath.split("?")[0]
  const isActive =
    href === pathWithoutQuery || (isActiveCallback ? isActiveCallback(router) : false)

  return (
    <Link href={href} passHref legacyBehavior>
      <Button
        onClick={onClick}
        color={variant === "text" ? "secondary" : "primary"}
        variant={variant}
        className={clsx(classes.button, className)}
        disableElevation>
        <Box className={clsx(isActive && variant === "text" && classes.buttonTextUnderline)}>
          {text}
        </Box>
      </Button>
    </Link>
  )
}
