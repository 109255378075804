import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { NAVIGATION_HEIGHT } from "app-constants/constants"
import { ContentContainer } from "components/content-container"
import React from "react"

interface TopNavigationProps {
  children: React.ReactNode
  className?: string
  showDivider?: boolean
}

const TopNavigationContainer = (props: TopNavigationProps) => {
  const { children, className, showDivider = true } = props

  return (
    <_Container className={className}>
      <ContentContainer>
        <_Nav role="region" aria-label="Top Navigation">
          {children}
        </_Nav>
      </ContentContainer>
      {showDivider && <_BottomDivider aria-hidden={true} />}
    </_Container>
  )
}

export default TopNavigationContainer

const _Container = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.gray.lightest,
  width: "100%",
  zIndex: 50,
})) as typeof Box

const _Nav = styled("nav")({
  display: "flex",
  alignItems: "center",
  flexFlow: "nowrap row",
  height: NAVIGATION_HEIGHT,
  width: "100%",
})

const _BottomDivider = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "calc((100vw - 100%) / 2 * -1)",
  bottom: 0,
  left: "calc((100vw - 100%) / 2 * -1)",
  borderBottom: `1px solid ${theme.palette.gray.darker}`,
}))
