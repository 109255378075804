// This is necessary to avoid the error: `Assertions require every name in the call target to be declared with an explicit type annotation.ts(2775)`
// `assertion.ts(16, 14): 'assert' needs an explicit type annotation.`
// https://github.com/microsoft/TypeScript/issues/36931#issuecomment-846131999
import { AssertionError } from "assert"
import { Current } from "lib/use-current"

type AssertCurrent = (current?: Current) => asserts current
export const assertCurrent: AssertCurrent = (
  current?: Current,
): asserts current is NonNullable<Current> => {
  if (!current) throw new AssertionError({ message: "current is undefined" })
}
