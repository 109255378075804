import CloseIcon from "@mui/icons-material/Close"
import ListAltIcon from "@mui/icons-material/ListAlt"
import { IconButton, styled, Typography } from "@mui/material"
import TopNavigationDropdown from "components/navigation/TopNavigationDropdown"
import useAuthDialog from "hooks/useAuthDialog"
import { useBoolean } from "hooks/useBoolean"
import React, { useEffect } from "react"
import FocusLock from "react-focus-lock"
import { isNilOrEmpty } from "utils/helpers"

interface TopNavigationHamburgerMenuProps {
  children: React.ReactNode
  menuLabel?: string
  icon?: "Menu" | "ListAlt"
}

const TopNavigationHamburgerMenu = (props: TopNavigationHamburgerMenuProps) => {
  const { children, icon = "Menu", menuLabel = undefined } = props
  const authDialog = useAuthDialog()

  const menuOpen = useBoolean(false)

  useEffect(() => {
    document.body.style.overflow = menuOpen.value ? "hidden" : "auto"
  }, [menuOpen.value])

  return (
    <FocusLock disabled={!menuOpen.value || !!authDialog.dialog}>
      <_IconButton
        isOpen={menuOpen.value}
        onClick={menuOpen.toggle}
        menuLabel={menuLabel}
        icon={icon}
      />
      <TopNavigationDropdown isOpen={menuOpen.value}>{children}</TopNavigationDropdown>
    </FocusLock>
  )
}

export default TopNavigationHamburgerMenu

interface IconButtonProps {
  onClick: () => void
  isOpen: boolean
  menuLabel?: string
  icon: "Menu" | "ListAlt"
}

const _IconButton = (props: IconButtonProps) => {
  const { isOpen, menuLabel, onClick } = props

  return (
    <_StyledIconButton aria-label="menu" aria-expanded={isOpen} onClick={onClick} size="large">
      {isOpen ? <CloseIcon /> : <ListAltIcon />}
      {!isNilOrEmpty(menuLabel) && <_MenuLabelTypography>{menuLabel}</_MenuLabelTypography>}
    </_StyledIconButton>
  )
}

const _StyledIconButton = styled(IconButton)(({}) => ({
  "&:hover": {
    borderRadius: "0.25rem",
  },
}))

const _MenuLabelTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  marginTop: theme.spacing(2.6),
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2.6),
  marginLeft: theme.spacing(2),
  width: "100%",
}))
